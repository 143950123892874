const config = require("../config");

const requiredError = (headerName, rowNumber, columnNumber) => {
    return `Spalte ${headerName} muss angegeben sein`
}

const validateError = (headerName, rowNumber, columnNumber) => {
    return `<div class="red">${headerName} is not valid in the <strong>${rowNumber} row</strong> / <strong>${columnNumber} column</strong></div>`
}

const uniqueError = (headerName, rowNumber) => {
    return `<div class="red">${headerName} is not unique at the <strong>${rowNumber} row</strong></div>`
}


/*
Kommazahl, maximal einer nachkommstelle, zwischen 1 und 165
console.log(isValidNumber("1")); // true
console.log(isValidNumber("164,9")); // true
console.log(isValidNumber("0,9")); // false (kleiner als 1)
console.log(isValidNumber("165")); // false (nicht kleiner als 165)
console.log(isValidNumber("123,45")); // false (mehr als eine Stelle nach dem Komma)
console.log(isValidNumber("12,3")); // true
console.log(isValidNumber("abc")); // false (keine Zahl)
console.log(isValidNumber("12.")); // false (falsches Format)
console.log(isValidNumber("12,0")); // false, weil 0 nicht nach komma sein darf
console.log(isValidNumber("12,00")); // false (mehr als eine Stelle nach dem Komma)
*/
function isValidNumber(value,from=1,to=165) {
    // Regulärer Ausdruck zur Überprüfung des Formats
    const regex = /^(\d+)(,\d{1})?$/;

    // Überprüfen, ob der String das richtige Format hat
    if (!regex.test(value)) {
        return false;
    }

    // Ersetzen des Kommas durch einen Punkt zur Konvertierung in eine Gleitkommazahl
    const number = parseFloat(value.replace(',', '.'));

    // Überprüfen, ob die Zahl im gewünschten Bereich liegt
    if (number < from || number >= to) {
        return false;
    }

    // Überprüfen, ob die Zahl eine Kommazahl mit einer Null nach dem Komma ist
    const commaIndex = value.indexOf(',');
    if (commaIndex !== -1 && value.charAt(commaIndex + 1) === '0') {
        return false;
    }

    return true;
}

const isValidValue = function (value,test=null) {
    let reg = /^([a-zA-Z0-9äÄöÖüÜß_, .-]+)$/;
    if(test!=null){
        window.alert(test);
    }
    return reg.test(value)
}

const isEmptyOrValidValue = function (value) {
    if(value == null || value === ""){
        return true;
    }else{
        return isValidValue(value);
    }
}

/**
 * Nur Alphanumerisch + _ und mindestens 3 Zeichen
 */
const isValidKeyValue = function (value) {
    // Regulärer Ausdruck zur Überprüfung auf alphanumerische Zeichen und Unterstriche
    const regex = /^[a-zA-Z0-9_]+$/;
    // Überprüfung der Länge und des Inhalts des Strings
    return value.length >= 3 && regex.test(value);
}


/*
// Beispiele
console.log(isGermanDateFormat("12.03.2024")); // true
console.log(isGermanDateFormat("31.04.2023")); // false (April hat nur 30 Tage)
console.log(isGermanDateFormat("01.13.2022")); // false (kein Monat 13)
console.log(isGermanDateFormat("29.02.2020")); // true (Schaltjahr)
console.log(isGermanDateFormat("29.02.2021")); // false (kein Schaltjahr)
console.log(isGermanDateFormat("12/03/2024")); // false (falsches Format)
*/
function isGermanDateFormat(dateString) {
    // Regulärer Ausdruck zur Überprüfung des deutschen Datumsformats
    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
    
    // Testen, ob der String dem Muster entspricht
    const match = dateString.match(regex);
    
    if (!match) {
        return false;
    }
    
    // Extrahieren der Teile des Datums
    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10);
    const year = parseInt(match[3], 10);
    
    // Überprüfung, ob das Datum gültig ist
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && 
           date.getMonth() === month - 1 && 
           date.getDate() === day;
}

function isEmpty(value) {
    if (value === null || value === undefined) {
        return true;
    }
    if (typeof value === 'string' && value.trim() === '') {
        return true;
    }
    if (Array.isArray(value) && value.length === 0) {
        return true;
    }
    if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) {
        return true;
    }
    if (typeof value === 'number' && isNaN(value)) {
        return true;
    }
    return false;
}

/*
// Beispiele
console.log(isValidEAN("4006381333931")); // true (gültiger EAN-13)
console.log(isValidEAN("4006381333930")); // false (ungültiger EAN-13)
console.log(isValidEAN("1234567890128")); // true (gültiger EAN-13)
console.log(isValidEAN("1234567890123")); // false (ungültiger EAN-13)
console.log(isValidEAN("400638133393"));  // false (zu kurz)
console.log(isValidEAN("40063813339310")); // false (zu lang)
console.log(isValidEAN("abcdefghijklm")); // false (keine Ziffern)
console.log(isValidEAN("")); // false (leer)
*/
function isValidEAN(ean) {
    // Entfernen aller Bindestriche und Leerzeichen
    ean = ean.replace(/[-\s]/g, '');
    // Prüfen, ob das Format korrekt ist (13 Ziffern)
    if (!/^\d{13}$/.test(ean)) {
        return false;
    }
    // Berechnung der Prüfziffer
    let sum = 0;
    for (let i = 0; i < 12; i++) {
        sum += (i % 2 === 0 ? 1 : 3) * parseInt(ean.charAt(i));
    }
    let checkDigit = parseInt(ean.charAt(12));
    sum = (10 - (sum % 10)) % 10;
    // EAN-13 ist gültig, wenn die Prüfziffer korrekt ist
    return sum === checkDigit;
}

function isValidDUNSNumber(str) {
    // Regulärer Ausdruck zur Überprüfung auf genau 9 Ziffern mit optionalen Bindestrichen
    const regex = /^(\d{2}-\d{3}-\d{4}|\d{9})$/;
    // Testen, ob der String eine gültige DUNS-Nummer ist
    return regex.test(str);
}

function isValidFliesenart(value) {
    const validValues = [
        "BODENFLIESE",
        "WANDFLIESE",
        "OKTAGON",
        "FACETTENFLIESE",
        "BRICK",
        "DEKOR",
        "CHEVRON",
        "STREIFENDEKOR",
        "HEXAGON",
        "AUSSENECKE",
        "MOSAIK_1X1",
        "MOSAIK_2,5X2,5",
        "MOSAIK_3,8X3,8",
        "MOSAIK_3,5X3,5",
        "MOSAIK_4,7X4,7",
        "MOSAIK_7,5X7,5",
        "MOSAIK_3X3",
        "MOSAIK_5X5",
        "MOSAIK_10X10",
        "MOSAIK_BASKET",
        "MOSAIK_MIX",
        "MOSAIK_RAUTE",
        "MOSAIK_3D",
        "KNOPFMOSAIK",
        "MOSAIK_DREIECK",
        "FISCHGRAET",
        "STREIFENMOSAIK",
        "SOCKEL",
        "RILLENSTUFE",
        "SCHENKELPLATTE",
        "SCHENKELSTUFE",
        "ECKE_SCHENKELSTUFE",
        "ECKE_SCHENKELSTUFE_LINKS",
        "ECKE_SCHENKELSTUFE_RECHTS",
        "FLORENTINERSTUFE",
        "ECKE_FLORENTINERSTUFE",
        "OUTDOOR",
        "BOOKMATCH_A",
        "BOOKMATCH_B",
        "ROHTAFEL",
        "HOHLKEHLSOCKEL",
        "HOHLKEHLSOCKEL_AUSSEN",
        "HOHLKEHLSOCKEL_INNEN",
        "JOLLY_AUSSEN",
        "JOLLY_INNEN",
        "EINLEGER",
        "MOSAIK",
        "BORDUERE",
        "BORDUERE_ECKE",
        "TROPFENMOSAIK"
    ];
    
    return validValues.includes(value);
}

function isValidKantenart(value) {
    const validKantenartValues = [
        "GETROMMELT"
    ];
    return validKantenartValues.includes(value);
}


function isValidOberflaeche(value) {
    const validOberflaecheValues = [
        "SAFE"
    ];
    return validOberflaecheValues.includes(value);
}

function isValidOptik(value) {
    const validOptikValues = [
        "STEINOPTIK",
        "HOLZOPTIK",
        "MARMOROPTIK",
        "BETONOPTIK",
        "COTTOOPTIK",
        "ORNAMENTOPTIK",
        "METALLOPTIK",
        "TEXTILOPTIK",
        "UNI"
    ];
    
    return validOptikValues.includes(value);
}

const val_AENDERUNG = function(value){
    return isValidValue(value) && isGermanDateFormat(value);
}
const val_ERSTELLUNG = function(value){
    return isValidValue(value) && isGermanDateFormat(value);
}
const val_DATENLIEFERANT = function(value){
    return isValidKeyValue(value);
}
const val_HERSTELLER = function(value){
    return isValidKeyValue(value);
}
const val_DUNS_NUMMER = function(value){
    return isEmpty(value) || isValidDUNSNumber(value);
}
const val_ARTIKELNUMMER = function(value){
    //TODO: Check if Artikelnummer ist eindeutig in dieser Tabelle. Also loop über alle Artikel!
    return isEmpty(value) || isValidKeyValue(value);
}
const val_HAENDLER_ARTIKELNUMMER = function(value){
    //TODO: Check if Händler Artikelnummer ist eindeutig in dieser Tabelle. Also loop über alle Artikel!
    return isEmpty(value) || isValidKeyValue(value);
}
const val_EAN_CODE = function(value){
    //TODO: Check if Händler Artikelnummer ist eindeutig in dieser Tabelle. Also loop über alle Artikel!
    return isEmpty(value) || isValidEAN(value);
}
const val_FLIESENART = function(value){
    return isValidFliesenart(value);
}
const val_SERIENNAME = function(value){
    return isValidKeyValue(value);
}
const val_HERSTELLER_FARBE = function(value){
    return isValidKeyValue(value);
}
const val_FARBGRUPPE = function(value){
    //TODO: Validierung weiterentwickeln
    return isValidKeyValue(value);
}
const val_OPTIK = function(value){
    //TODO: Validierung weiterentwickeln
    return isValidKeyValue(value);
}
const val_BREITE = function(value){
    return isValidNumber(value,1,165);
}
const val_TIEFE = function(value){
    return isValidNumber(value,1,325);
}
const val_HOEHE = function(value){
    //TODO: Die Höhe ist dann einzutragen, wenn es sich um Schenkelplatten, Schenkelstufen oder deren Ecken handelt.  
    return isEmpty(value) || isValidNumber(value,1,325);
}

const val_ME_FORMAT = function(value){
    return !isEmpty(value) && value === 'CM'
}

class ValidationController {

    getCSVConfig(){
        return {
            headers: [
                { name: 'AENDERUNG', inputName: 'AENDERUNG', required: true, requiredError,                             validate: val_AENDERUNG, validateError },
                { name: 'ERSTELLUNG', inputName: 'ERSTELLUNG', required: true, requiredError ,                          validate: val_ERSTELLUNG, validateError },
                { name: 'DATENLIEFERANT', inputName: 'DATENLIEFERANT', required: true, requiredError ,                  validate: val_DATENLIEFERANT, validateError },
                { name: 'HERSTELLER', inputName: 'HERSTELLER', required: true, requiredError,                           validate: val_HERSTELLER, validateError },
                { name: 'DUNS_NUMMER', inputName: 'DUNS_NUMMER', required: false, requiredError,                        validate: val_DUNS_NUMMER, validateError },
                { name: 'HAENDLER_ARTIKELNUMMER', inputName: 'HAENDLER_ARTIKELNUMMER', required: false, requiredError,  validate: val_HAENDLER_ARTIKELNUMMER, validateError  },
                { name: 'ARTIKELNUMMER', inputName: 'ARTIKELNUMMER', required: true, requiredError,                     validate: val_ARTIKELNUMMER, validateError },
                { name: 'EAN_CODE', inputName: 'EAN_CODE', required: false, requiredError,                              validate: val_EAN_CODE, validateError  },
                { name: 'FLIESENART', inputName: 'FLIESENART', required: true, requiredError ,                          validate: val_FLIESENART, validateError },
                { name: 'SERIENNAME', inputName: 'SERIENNAME', required: true, requiredError ,                          validate: val_SERIENNAME, validateError },
                { name: 'HERSTELLER_FARBE', inputName: 'HERSTELLER_FARBE', required: true, requiredError,               validate: val_HERSTELLER_FARBE, validateError },
                { name: 'FARBGRUPPE', inputName: 'FARBGRUPPE', required: true, requiredError ,                          validate: val_FARBGRUPPE, validateError },
                { name: 'OPTIK', inputName: 'OPTIK', required: true, requiredError ,                                    validate: val_OPTIK, validateError },
                { name: 'BREITE', inputName: 'BREITE', required: true, requiredError ,                                  validate: val_BREITE, validateError },
                { name: 'TIEFE', inputName: 'TIEFE', required: true, requiredError ,                                    validate: val_TIEFE, validateError },
                { name: 'HOEHE', inputName: 'HOEHE', required: false, requiredError ,                                   validate: val_HOEHE, validateError  },
                { name: 'ME_FORMAT', inputName: 'ME_FORMAT', required: true, requiredError ,                            validate: val_ME_FORMAT, validateError },
                { name: 'STAERKE', inputName: 'STAERKE', required: true, requiredError ,                                validate: isValidValue, validateError },
                { name: 'ME_STAERKE', inputName: 'ME_STAERKE', required: true, requiredError ,                          validate: isValidValue, validateError },
                { name: 'KANTENART', inputName: 'KANTENART', required: true, requiredError ,                            validate: isValidValue, validateError },
                { name: 'OBERFLAECHE', inputName: 'OBERFLAECHE', required: true, requiredError ,                        validate: isValidValue, validateError },
                { name: 'R_WERT', inputName: 'R_WERT', required: false, requiredError,                                  validate: isEmptyOrValidValue, validateError  },
                { name: 'BARFUSSWERT', inputName: 'BARFUSSWERT', required: false, requiredError ,                       validate: isEmptyOrValidValue, validateError  },
                { name: 'VERDRAENGUNG', inputName: 'VERDRAENGUNG', required: false, requiredError,                      validate: isEmptyOrValidValue, validateError  },
                { name: 'ABRIEB', inputName: 'ABRIEB', required: false, requiredError,                                  validate: isEmptyOrValidValue, validateError  },
                { name: 'FLECKBESTAENDIGKEIT', inputName: 'FLECKBESTAENDIGKEIT', required: false, requiredError,        validate: isEmptyOrValidValue, validateError  },
                { name: 'VARIATIONEN', inputName: 'VARIATIONEN', required: false, requiredError ,                       validate: isEmptyOrValidValue, validateError  },
                { name: 'ME', inputName: 'ME', required: true, requiredError ,                                          validate: isValidValue, validateError },
                { name: 'PREISGRUPPE', inputName: 'PREISGRUPPE', required: false, requiredError ,                       validate: isEmptyOrValidValue, validateError  },
                { name: 'LISTENPREIS', inputName: 'LISTENPREIS', required: true, requiredError ,                        validate: isValidValue, validateError },
                { name: 'STUECK_PRO_KARTON', inputName: 'STUECK_PRO_KARTON', required: true, requiredError ,            validate: isValidValue, validateError },
                { name: 'M2_PRO_KARTON', inputName: 'M2_PRO_KARTON', required: false, requiredError ,                   validate: isEmptyOrValidValue, validateError  },
                { name: 'ME_PRO_KARTON', inputName: 'ME_PRO_KARTON', required: false, requiredError ,                   validate: isEmptyOrValidValue, validateError  },
                { name: 'KG_PRO_KARTON', inputName: 'KG_PRO_KARTON', required: false, requiredError ,                   validate: isEmptyOrValidValue, validateError  },
                { name: 'KARTON_PRO_PALETTE', inputName: 'KARTON_PRO_PALETTE', required: false, requiredError ,         validate: isEmptyOrValidValue, validateError  },
                { name: 'M2_PRO_PALETTE', inputName: 'M2_PRO_PALETTE', required: false, requiredError ,                 validate: isEmptyOrValidValue, validateError  },
                { name: 'KG_PRO_PALETTE', inputName: 'KG_PRO_PALETTE', required: false, requiredError ,                 validate: isEmptyOrValidValue, validateError  },
                { name: 'MATERIAL', inputName: 'MATERIAL', required: true, requiredError ,                              validate: isValidValue, validateError },
                { name: 'AUSLAUFARTIKEL', inputName: 'AUSLAUFARTIKEL', required: true, requiredError ,                  validate: isValidValue, validateError },
    
                //{ name: 'First Name', inputName: 'firstName', required: true, requiredError },
                //{ name: 'Last Name', inputName: 'lastName', required: true, requiredError, optional: true },
                //{ name: 'Email', inputName: 'email', required: true, requiredError, unique: true, uniqueError, validate: isEmailValid, validateError },
                //{ name: 'Password', inputName: 'password', required: true, requiredError, validate: isPasswordValid, validateError },
                //{ name: 'Roles', inputName: 'roles', required: true, requiredError, isArray: true }
            ],
            isColumnIndexAlphabetic: true
        }
    }

}

module.exports = ValidationController;